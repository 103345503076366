import styled from 'styled-components';
import { BREAKPOINT, COLOR } from '@/utils/constants';

export const Main = styled.main`
  background-color: #f8f8f8;
  h1 {
    color: ${COLOR.BLACK};
  }
`;

export const BrDesktop = styled.br`
  display: none;
  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
  }
`;
