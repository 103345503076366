import React from 'react';
import Helmet from 'react-helmet';
import { SecondaryHero } from '@latitude/hero';
import { Box } from '@latitude/box';
import { Heading2 } from '@latitude//heading';
import { Text } from '@latitude/text';
import { COLOR } from '@latitude/core/utils/constants';
import UploadCards from './components/_uploadCards';
import { Main, BrDesktop } from './components/_uploadStyle';
import Layout from '../../components/layout';

const Page = ({ location }) => (
  <Layout location={location}>
    <Main className="navigation-spacer">
      <Helmet>
        <link rel="canonical" href={`${location.href}`} />
        <title>Document upload error | Gem by Latitude</title>
        <meta name="description" content="Document upload error." />
      </Helmet>
      <SecondaryHero
        heading={
          <>
            Our upload tool <BrDesktop />
            had an error
          </>
        }
      />
      <Box.Section backgroundColor="transparent" padding="40px 0 0">
        <Heading2 align="center" color={COLOR.BLACK}>
          Continue uploading your documents
        </Heading2>
        <Text align="center">
          Apologies for the inconvenience, please continue uploading your
          documents.
        </Text>
      </Box.Section>

      <UploadCards />
    </Main>
  </Layout>
);

export default Page;
