/* eslint-disable import/no-dynamic-require */
import React from 'react';
import styled from 'styled-components';
import { Link } from '@latitude/link';
import { Box } from '@latitude/box';
import {
  BUTTON_STYLE,
  BREAKPOINT,
  PADDING
} from '@latitude/core/utils/constants';
import CardTile from '../../contact-us/components/_CardTile';
import uploadLinks from '../cards.json';

export default () => (
  <Box.Section backgroundColor="transparent" padding="40px 0 10px">
    <Group>
      {uploadLinks.map(item => (
        <GroupItem>
          <StyledCardTile
            key={item.title}
            image={
              <img src={require(`../images/${item.image}`)} alt={item.title} />
            }
            cardImageOrientation={item.cardImageOrientation}
            title={item.title}
            content={
              <CardTileContent>
                <Link
                  button={BUTTON_STYLE.TERTIARY}
                  href={`https://upload.gemfinance.co.nz/fileupload/income?cardType=${item.id}`}
                  trackEventData={{
                    location: 'Continue uploading your documents'
                  }}
                >
                  Upload your documents
                </Link>
              </CardTileContent>
            }
          />
        </GroupItem>
      ))}
    </Group>
  </Box.Section>
);

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${BREAKPOINT.MD}) {
    justify-content: space-between;
  }
`;

const GroupItem = styled.div`
  width: 100%;
  max-width: 350px;

  @media (min-width: ${BREAKPOINT.MD}) {
    flex: 0 0 calc(50% - 15px);
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    flex: 0 0 calc(33.333333% - 15px);
  }
`;

const StyledCardTile = styled(CardTile)`
  width: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;

  sup {
    font-size: 0.6em;
    top: -0.6em;
  }
`;

const CardTileContent = styled.div`
  padding: ${PADDING.P24};
  text-align: center;
  a {
    display: inline-block;
  }
`;
